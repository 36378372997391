<template>
  <ContentEditorLayout class="web-content-editor">
    <div v-if="hasCorruptGameConfigs" class="info">
      <p>
        Achtung, es wurden fehlerhafte Game-Konfigurationen entdeckt. Bitte
        passe alle rot markierten Game-Konfigurationen an.
      </p>
    </div>

    <div
      v-if="gamesData && gamesData.gameSchemas.length"
      class="game-selection"
    >
      <div>
        <label class="label" for="games-selector">Game wählen</label>
        <select id="games-selector" v-model="selectedGame">
          <optgroup v-for="g in groupedGames" :key="g.group" :label="g.group">
            <option v-for="game in g.games" :key="game.id" :value="game">
              <span>{{ game.id }} - {{ game.name }}</span>
              <span v-if="game.hasConfig">
                {{ game.isConfigValid ? ' ✅ ' : ' ⭕ ' }}</span
              >
            </option>
          </optgroup>
        </select>
      </div>
      <div v-if="selectedGame" class="revisions">
        <label class="label" for="revision-selector"
          >Revision (Total {{ currentGameRevisions?.length ?? 0 }})</label
        >
        <select id="revision-selector" v-model="selectedGameRevision">
          <option
            v-for="revision in currentGameRevisions"
            :key="`revision-${selectedGame.id}-${revision.revision}`"
            :value="revision.revision"
          >
            <span>{{ revision.revision }}</span>
            <span>{{ revision.isValid ? ' ✅ ' : ' ⭕ ' }}</span>
          </option>
        </select>
        <div>
          <ButtonSecondary
            type="button"
            :label="`Neue Revision erstellen (${nextRevision})`"
            @click="createNewRevision()"
          />
        </div>
      </div>
    </div>

    <GameConfigEditor
      v-if="currentGameSchema"
      :schema="currentGameSchema"
      :content="currentGameConfig"
      @save="saveConfig($event)"
    />
  </ContentEditorLayout>
  <div v-if="gamesData && gamesData.gameSchemas.length === 0">
    Es wurden keine Schemas für die Game-Konfigurationen gefunden. Bitte
    <pre style="display: inline">npm run update-game-schemas</pre>
    ausführen.
  </div>
</template>

<script setup lang="ts">
import GameConfigEditor from '~/content-editor/GameConfigEditor.vue';
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { GameConfigDto, GameDto, ValidatedGameConfigDto } from '@shared/game';
import useContentEditorRepository from '~/content-editor/useContentEditorRepository';
import { ContentEditorGamesDataDto } from '@shared/content-editor';
import ContentEditorLayout from '~/content-editor/ContentEditorLayout.vue';
import ButtonSecondary from '~/common/buttons/ButtonSecondary.vue';

const { getGamesData, saveGameConfig } = useContentEditorRepository();

const gamesData: Ref<ContentEditorGamesDataDto | null> = ref(null);

const selectedGame: Ref<GameDto | null> = ref(null);
const selectedGameRevision = ref<number | null>(null);
// const selectedGameRevision: Ref<
//   ValidatedGameConfigDto['revisions'][number] | null
// > = ref(null);

type GameDtoWithValidatedConfig = {
  isConfigValid: boolean | undefined;
  hasConfig: boolean;
} & GameDto;

interface GroupedGames {
  group: string;
  games: GameDtoWithValidatedConfig[];
}

const groupedGames = computed(() => {
  if (!gamesData.value?.gameStructure || !gamesData.value?.gameConfigs) {
    return [];
  }

  const groups: GroupedGames[] = [];

  gamesData.value.gameStructure.worlds.forEach((world) => {
    world.topics.forEach((topic) => {
      topic.modules.forEach((module) => {
        const group = `${world.name} > ${topic.name} > ${module.name}`;
        groups.push({
          group,
          games: module.games.map((g) =>
            checkGameWithConfig(g, findGameConfigForGameId(g.id)),
          ),
        });
      });
    });
  });
  return groups;
});

const currentGameSchema = computed(() => {
  if (selectedGame.value && gamesData.value?.gameSchemas) {
    const schemas = gamesData.value.gameSchemas;
    return schemas.find((s) => s.gameRef === selectedGame.value?.ref) ?? null;
  }
  return null;
});

const currentGameRevisions = computed(() => {
  if (selectedGame.value && gamesData.value?.gameConfigs) {
    const gameConfigs = gamesData.value?.gameConfigs;
    const gameConfig = gameConfigs.find(
      (c) => c.gameId === selectedGame.value?.id,
    );

    return gameConfig?.revisions;
  }
  return [];
});

const currentGameConfig = computed(() => {
  if (
    selectedGame.value &&
    selectedGameRevision.value !== null &&
    gamesData.value?.gameConfigs
  ) {
    const gameConfigs = gamesData.value?.gameConfigs;
    const gameConfig = gameConfigs.find(
      (c) => c.gameId === selectedGame.value?.id,
    );
    const revision = gameConfig?.revisions?.find(
      (r) => r.revision === selectedGameRevision.value,
    );
    return revision ? JSON.stringify(revision.config) : '{}';
  }
  return null;
});

const hasCorruptGameConfigs = computed(() => {
  if (!gamesData.value?.gameConfigs.length) {
    return false;
  }

  return gamesData.value.gameConfigs
    .map((c) => findLatestRevision(c))
    .some((c) => !c?.isValid);
});

watch(selectedGame, () => {
  if (selectedGame.value && currentGameRevisions.value) {
    selectedGameRevision.value = Math.max(
      ...currentGameRevisions.value.map((r) => r.revision),
    );
  }
});

const latestRevision = computed(() => gamesData.value?.latestRevision);
const nextRevision = computed(() => (latestRevision.value ?? 0) + 1);

onMounted(async () => {
  await loadGameData();
});

function findGameConfigForGameId(id: number) {
  return gamesData.value?.gameConfigs.find((c) => c.gameId === id) || null;
}

function findLatestRevision(config: ValidatedGameConfigDto) {
  const highestRevision = Math.max(...config.revisions.map((r) => r.revision));
  return config.revisions.find((r) => r.revision === highestRevision);
}

function checkGameWithConfig(
  game: GameDto,
  config: null | ValidatedGameConfigDto,
): {
  isConfigValid: boolean | undefined;
  hasConfig: boolean;
} & GameDto {
  const hasConfig = !!config;
  return {
    ...game,
    hasConfig,
    isConfigValid: hasConfig ? findLatestRevision(config)?.isValid : undefined,
  };
}

async function loadGameData() {
  gamesData.value = await getGamesData();
}

async function saveConfig(config: string) {
  if (!selectedGame.value || selectedGameRevision.value === null) {
    return;
  }

  const gameConfigDto: GameConfigDto = {
    gameId: selectedGame.value.id,
    gameRef: selectedGame.value.ref,
    revision: selectedGameRevision.value,
    config: JSON.parse(config),
  };

  await saveGameConfig(gameConfigDto);
  await loadGameData();

  alert('Game Konfiguration gespeichert!');
}

async function createNewRevision() {
  if (!selectedGame.value || !gamesData.value?.gameConfigs) {
    return;
  }

  if (
    !confirm(
      'Soll wirklich eine neue Revision erstellt werden? Die aktuelle Konfiguration wird dabei kopiert.',
    )
  ) {
    return;
  }

  const gameConfigs = gamesData.value?.gameConfigs;
  const gameConfig = gameConfigs.find(
    (c) => c.gameId === selectedGame.value?.id,
  );

  if (!gameConfig) {
    return;
  }

  const revision = findLatestRevision(gameConfig);

  if (!revision) {
    return;
  }

  const newRevision = nextRevision.value;
  const gameConfigDto: GameConfigDto = {
    gameId: selectedGame.value.id,
    gameRef: selectedGame.value.ref,
    revision: newRevision,
    config: JSON.parse(JSON.stringify(revision.config)),
  };

  await saveGameConfig(gameConfigDto);
  await loadGameData();

  alert('Neue Revision für Game Konfiguration erstellt!');

  selectedGameRevision.value = newRevision;
}
</script>

<style scoped lang="scss">
.web-content-editor {
  > .info {
    padding: 0 0 2rem 0;
    color: var(--color-error);
  }
}

.game-selection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: var(--color-gull-gray-200);
  margin-bottom: 2rem;

  > .revisions {
    display: flex;
    gap: 1rem;
  }
}

.label {
  display: inline-block;
  margin-right: 0.5rem;
}
</style>
